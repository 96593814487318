#map {
    svg {
        display: block;
        width: 100%;
        max-width: 1400px;
        height: auto;
        margin: 0 auto;
    }
}

.highlight {
    fill: #76A8AC;
    transition: 0.2s fill linear;
    
}

#cities {
    position: absolute;
    width: 250px;
    a:hover {
        color:#025CA6;
    }
    a:active {
        color: #000080;
       } 
    a {
        font-family: Roboto;
        color: #FFFFFF;
        font-size: 20px;
        line-height: 200%;
    }
    p {
        font-family: Roboto;
        color: #FFFFFF;
        font-size: 20px;
    }


    
    // .centralReg {
    //     position: relative;
    //     right: 40px;
    //     bottom: 50px;
    //     display: inline;
    // }
}




// .regions {
//     visibility: hidden;
//     display: flex;
//     flex-direction: column;
//     width: 150px;
//     a:active {
//         color: #001AFF;
//        } 
//     a {
//         font-family: Roboto;
//         color: #FFFFFF;
//         font-size: 24px;
//         line-height: 140%;

//     }
// }
// .hover:hover {
//     fill: blue;
// }
