.header {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 57px;
}

.header__left {
    margin-left: 11vw;
}

.header__left--title {
    color: #ffffff;
    width: 35vw;
    font-family: "GetVoIP Grotesque", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
}


.header__right {
    margin-right: 11vw;
    display: flex;
    .header__right--discriptione {
        margin-top: -18px;
        width: 25vw;
        line-height: 180%;

        p {
            font-size: 18px;
            font-family: Roboto, sans-serif;
            color: #ffffff;
        }
    }
}

#num{
    font-weight: 700;
}
