@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'GetVoIP Grotesque';
    src: url('/src/fonts/GetVoIP\ Grotesque.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
@font-face {
    font-family: 'GetVoIPGrotesque';
    src: url('/src/fonts/GetVoIPGrotesque.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  