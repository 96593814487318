.region {
    margin-left: 400px;
    width: 200px;
    height: 200px;
    background-color: green;
    margin: 10px;
    float: left;
}

#cities {
    position: absolute;
    a {
        display: block;
    }
}


.depaty-list {
    flex-wrap: wrap;
    padding-top: 66px;
    width: 78vw;
    margin: auto;
    font-family: Roboto, serif;
    .cards {
        &__title {
            width: 45vw;
            padding-bottom: 12px;
            font-size: 38px;
        }
        &__description {
            font-size: 20px;
            width: 38vw;
            margin-bottom: 52px;
        }
    }
    &__title {
        color: #ffffff;
        font-size: 26px;
        line-height: 42px;
        width: 42vw;
        padding-bottom: 40px;
    }
    &__cards {
        box-sizing: border-box;
        display: flex;
        height: 340px;
        padding: 26px;
        background: #ffffff;
        border-radius: 18px;
    }
    &__photo {
        position: relative;
        bottom: 105px;
        left: 7vw;
    }
}

.cards__links--links {
        margin-bottom: 50px;
        display: flex;
    a {
        margin-right: 1vw;
    }
}

#second-list {
    margin-top: 60px;
}