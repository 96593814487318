.description__wrapper {
    display: flex;
    justify-content: space-between;
    width: 72vw;
    font-family: Roboto, serif;
    margin: auto;
    padding-top: 151px;
    .description-title {
        font-family: Roboto, serif;
        font-size: 48px;
        color: #ffffff;
        span {
            color: #ee4f34;
        }
    }
    
    .description-content {
        margin-top: 24px;
        text-decoration-line: underline;
        font-size: 24px;
        line-height: 28px;
        color: #4F595F;
        font-size: 24px;
        text-decoration: none;
        
        a:hover {
            color:#FFFFFF;
        }
        a {
            color: #4F595F;
            border-bottom: 1.5px solid currentColor;
            // background-repeat: repeat-x;
            // background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/78779/bacon.svg");
            // background-position: 0 1.06em;
            // background-size: 28px 9px;
            line-height: 35px;
            font-size: 24px;
            text-decoration: none;
        }
    }
    .description__right {
        display: flex;
        justify-content: space-between;
        .cursor {
            position: relative;
            top: -40px;
        }
        .description-rightmouse {
            width: 191px;
            font-size: 18px;
            line-height: 23px;
            color: #ffffff;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
}